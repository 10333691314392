import React, { useState } from "react";
import styled from "styled-components";
import { FaArrowDown } from "react-icons/fa";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; 
  max-width: 600px; 
  height: 500px;
  margin: 30px auto 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px 1px #a3d4cb;
  overflow: auto;
  overflow-x: hidden;

  @media screen and (min-width: 600px) {
    height: 300px;
  }
`;

const BlocTabs = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row; 
  }
`;

const TabButton = styled.button`
  flex: 1; 
  padding: 15px;
  text-align: center;
  background: #1d9682;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: border-box;
  position: relative;
  outline: none;
  transition: background-color 0.3s ease;

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }

  &.active-tabs {
    background: white;
    border-bottom: 3px solid #03989e;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 2px);
      height: 5px;
      background: rgb(88, 147, 241);
    }
  }

  @media screen and (min-width: 600px) {
    width: auto; 
    flex: 1; 
  }
`;

const ContentTabs = styled.div`
  flex-grow: 1;
  height: 100%;
`;

const Content = styled.div`
  background: transparent;
  padding: 20px;
  width: 100%; 
  overflow-y: auto; 
  display: ${props => (props.isActive ? 'block' : 'none')};
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const Tabs = ({ pageContext }) => {
  const { service } = pageContext;
  const [toggleState, setToggleState] = useState(2);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <Container>
      <BlocTabs>
        <TabButton
          className={toggleState === 1 ? "active-tabs" : ""}
          onClick={() => toggleTab(1)}
        >
          Service Description {toggleState !== 1 && <FaArrowDown />}
        </TabButton>
        <TabButton
          className={toggleState === 2 ? "active-tabs" : ""}
          onClick={() => toggleTab(2)}
        >
          How to Book{toggleState !== 2 && <FaArrowDown />}
        </TabButton>
        <TabButton
          className={toggleState === 3 ? "active-tabs" : ""}
          onClick={() => toggleTab(3)}
        >
          Payment and Sample collection {toggleState !== 3 && <FaArrowDown />}
        </TabButton>
      </BlocTabs>

      <ContentTabs>
        <Content isActive={toggleState === 1}>
          {service.description ? (
            <p>{service.description}</p>
          ) : (
            <p>Description coming soon</p>
          )}
        </Content>

        <Content isActive={toggleState === 2}>
            <p>Login, book a test or procedure then checkout</p>
            <p>You can also book through Whatsapp by clicking on the Chat button</p>
            <p>You can also send your request through Upload Lab request <UploadFileOutlinedIcon/></p>
            <p>Our personnel will get back to you with processing details</p>
        </Content>
        <Content isActive={toggleState === 3}>
            <p>Payment through Mpesa or Cash</p>
            <p>Sample collection rates if applicable:</p>
            <p>Collection within Nairobi, sameday; ksh 300</p>
            <p>Collection within Nairobi, the following day; ksh 250</p>
            {/* <p>Collection within Nairobi, Urgent; ksh 350</p> */}
            <p>Collection outside Nairobi, varies</p>
        </Content>
      </ContentTabs>
    </Container>
  );
};

export default Tabs;
