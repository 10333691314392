import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import '../style/product.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'gatsby'
import SocialCue from '../images/social_cue.svg'
import Footer from '../components/homeNav/footer/Footer'
import Med from '../components/med/ServiceCard'
import Alert from 'react-bootstrap/Alert'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Drugicon from '../images/lab.png'
import Next from '../images/right-arrow.svg'
import Prev from '../images/left-arrow.svg'
import PageNav from '../components/homeNav/PageNavService'
import { navigate } from 'gatsby'
import { BsWhatsapp } from 'react-icons/bs'
import { BsShareFill } from 'react-icons/bs'
import BotNav from '../components/bottomNav'
import {
  CloseIcon,
  ContentWrapper,
  IconCont,
  ModalDesc,
  ModalTxt,
  StyledModal,
  UploadBtn,
} from '../components/findForm/formElements'
import { MdAddCircleOutline } from 'react-icons/md'
import axios from 'axios'
import { StoreName } from '../components/med/medElements'
import Seo from '../components/seo'
import useAuthState from '../stores/auth'
import useCartState from '../stores/cart'
import { toast } from 'react-toastify'
import Tabs from '../components/serviceTab';


const HeaderContainer = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 1px #a3d4cb;
  padding: 17px;
  width: 87%;
  align-self: center;
  margin: auto;
  border-radius: 10px;
  @media screen and (min-width: 320px) {
    flex-direction: column;
    width: 100%;
    padding: 0;
    background-color: white;
    margin: 5px 0 0.1em;
  }
  @media screen and (min-width: 1440px) {
    justify-content: center;
    align-items: normal;
    flex-direction: column;
    width: 80%;
    padding: 0;
  }
`
const AddCartBtn = styled.button`
  width: 100%;
  padding: 10px;
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    transition: all 0.2s ease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`
const ProductSlideWrapper = styled.div`
  padding: 1em 1.5em;
  margin: 1em 0;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
`

const ImgContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`
const ImgContainerM = styled.div`
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export default function Service({ pageContext, location }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Prev}
      alt="prevArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Next}
      alt="nextArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: 'anticipated',
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  }

  const data2 = useStaticQuery(graphql`
    query AltServiceQuery {
      allServices {
        edges {
          node {
            id
            name
            description
            serviceType
            price
            showPrice
            createdAt
            updatedAt
            tags
            category
            image
            discount
            shop {
              name
              _id
              physicalAddress {
                county
                street
              }
            }
          }
        }
      }
    }
  `)

  const servicesR = data2.allServices.edges.map(item => {
    return item.node
  })
  const service = pageContext.service
  const shop = pageContext.shop

  const preferredShop = process.env.GATSBY_PREFERRED_SHOP

  const services = servicesR?.sort((a, b) => {
    if (a.shop._id === preferredShop) return -1
    if (b.shop._id === preferredShop) return 1
    return 0
  })

  const { user } = useAuthState()

  const [show, setShow] = React.useState(false)
  const [selectedPrescription, setSelectedPrescription] = React.useState({})
  const [isPrescriptionPicked, setIsPrescriptionPicked] = React.useState(false)
  const [prescriptionUrl, setPrescriptionUrl] = React.useState('')
  const [cloudinaryImage, setCloudinaryImage] = React.useState({})

  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [error, setError] = React.useState('')

  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    setSuccess('')
    setError('')
  }

  const handleChangePrescription = async (event) => {
    const file = event.target.files[0];
    const serviceId = pageContext.service.id;

    if (file) {
        const userId = user ? user.id : undefined;

        if (!userId) {
            console.error('User ID is not available');
            return;
        }

        try {
            await uploadPrescription(file, userId);
            setSelectedPrescription(file);

            const fileUrl = URL.createObjectURL(file);
            setPrescriptionUrl(fileUrl);
            setIsPrescriptionPicked(true);

            localStorage.setItem(`isPrescriptionPicked_${serviceId}`, true);
            localStorage.setItem(`prescriptionFile_${serviceId}`, JSON.stringify(file));
            localStorage.setItem(`prescriptionUrl_${serviceId}`, fileUrl);

            navigate('/prescription-checkout', { state: { serviceId } });
        } catch (error) {
            console.error('Error uploading Lab test:', error);
        }
    }
};

useEffect(() => {
    const serviceId = pageContext.service.id; 
    const isPrescriptionPicked = localStorage.getItem(`isPrescriptionPicked_${serviceId}`) === 'true';
    const storedFile = localStorage.getItem(`prescriptionFile_${serviceId}`);
    const storedUrl = localStorage.getItem(`prescriptionUrl_${serviceId}`);

    if (isPrescriptionPicked && storedFile && storedUrl) {
        setIsPrescriptionPicked(true);
        setSelectedPrescription(JSON.parse(storedFile));
        setPrescriptionUrl(storedUrl);
    }
}, [pageContext.service.id]); 


const handleReset = () => {
  setIsPrescriptionPicked(false);
  setSelectedPrescription(null);
  setPrescriptionUrl('');

  const serviceId = pageContext.service.id;

  localStorage.removeItem(`isPrescriptionPicked_${serviceId}`);
  localStorage.removeItem(`prescriptionFile_${serviceId}`);
  localStorage.removeItem(`prescriptionUrl_${serviceId}`);
};

const isValidObjectId = (id) => /^[a-fA-F0-9]{24}$/.test(id);

  const uploadPrescription = async (file, userId) => {
    console.log('UserId before sending:', userId);

    if (!isValidObjectId(userId)) {
        console.error('Invalid userId format');
        return;
    }

    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);

        setIsLoading(true);
        const result = await axios.post(
            `${process.env.GATSBY_API_URL}/servicesRequest`,
            formData
        );

        setCloudinaryImage(result.data);
        setIsPrescriptionPicked(true);
        setIsLoading(false);
        setSuccess('Uploaded lab request successfully');
    } catch (err) {
        const message = err.response?.data?.message || 'An error occurred';
        setIsLoading(false);
        setError(message);
    }
};


  const { cart, setCart, increment } = useCartState()

  const addToCart = () => {
    const newCartProduct = {
      id: service.id,
      name: service.name,
      description: service.description,
      serviceType: service.serviceType,
      shop: service.shop.name,
      price: service.price,
      image: service?.image?.replace(
        'https://res.cloudinary.com/tripleaim-software/image/upload/',
        'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_230,f_auto/',
      ),
      quantity: 1,
      prescription: cloudinaryImage?.secure_url,
    }

    if (cloudinaryImage) {
      newCartProduct.prescription = cloudinaryImage.secure_url
    }

    const cartIndex = cart.findIndex(item => item.id === service.id)

    //not in cart
    if (cartIndex === -1) {
      setCart([...cart, newCartProduct])
      toast.success(`Item added: ${newCartProduct.name}`)
    } else {
      increment(cartIndex)
      toast.success(
        `You know have ${cart[cartIndex].quantity}  ${newCartProduct.name} in your cart `,
      )
    }
  }

  const keyword = service.name + ' price in Kenya - Afyabook'
  const keyword2 = service.name + ' price in Kenya - Afyabook,blood tests in kenya, stool tests in kenya,csf tests in kenya, urinalysis, biochemistry, ct scan in kenya, x ray in kenya, x-ray in kenya, ultrasound, mri in kenya'
    
  const keyword_desc = service.name + ' price in Kenya. Book for laboratory, diagnostic or imaging tests online and have your samples collected from your home or office. Get your results delivered to you electronically. Afyabook is a platform that connects you to the best healthcare providers in Kenya.'
  
  const siteurl =
    'https://www.afyabook.com/services/' +
    service.name?.replace(/ /g, '-').toLowerCase() +
    '/' +
    service.id

  const imageM =
    service.image?.replace(
      'https://res.cloudinary.com/tripleaim-software/image/upload/',
      'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_184,f_auto/',
    ) || Drugicon

  const imageDesk =
    service.image?.replace(
      'https://res.cloudinary.com/tripleaim-software/image/upload/',
      'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_320,f_auto/',
    ) || Drugicon

  return (
    <div className="main-content">
      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword+keyword2}
        img={imageM || Drugicon}
        siteUrl={siteurl}
        canonical={siteurl}
      ></Seo>
      <div className="container-fluid" style={{ padding: '0', margin: '0' }}>
        <PageNav />
        <div className=" top-container">
          <HeaderContainer>
            <div className="topinnerCont">
              <div className="innerHeader">
                <h3 className="prodHead">Service details</h3>
              </div>
            </div>
            <div className="bottominnerCont">
              <div>
                <div className="prodImgCont">
                  {/* <div className="col-xs-2 prodImgContainer">
                {thumbnail.map(item => {
                  const { id, img } = item
                  return (
                    <div style={{ padding: "10px" }}>
                      <div className="prodImgArray" key={id}>
                        <div>
                          <img src={img} alt="" className="thumbnailImg" />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div> */}
                  <ImgContainer>
                    <img
                      style={{ width: '100%' }}
                      src={imageDesk}
                      alt={service.name}
                    />
                  </ImgContainer>
                  <ImgContainerM>
                    <img
                      style={{ width: '100%' }}
                      src={imageM}
                      alt={service.name}
                    />
                  </ImgContainerM>
                </div>
              </div>

              <div className=" prodCardDetail">
                <div>
                  <div>
                    <h1 className="prodTitle">
                      {service.name.charAt(0).toUpperCase() + service.name.slice(1)}
                    </h1>
                    <h2 className="prodTitle">
                      {service.serviceType.charAt(0).toUpperCase() + service.serviceType.slice(1)}
                    </h2>
                    
                  </div>
                  
                  {user?.admin && (
                    <div className="prodTitleManuf">
                      <StoreName
                        to={`/pharmacy/${shop.name
                          .replace(/ /g, '-')
                          .toLowerCase()}`}
                      >
                        {' '}
                        <strong>
                          {shop.name.charAt(0).toUpperCase() +
                            shop.name.slice(1).toLowerCase()}
                        </strong>{' '}
                        <strong>
                          -
                          {shop.location?.street.charAt(0).toUpperCase() +
                            shop.location?.street.slice(1).toLowerCase()}
                        </strong>
                      </StoreName>{' '}
                    </div>
                  )}
                  <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org/",
                        "@type": "Service",
                        "name": "${keyword}",
                        "description": "${keyword_desc}",
                        "image": "${imageM || Drugicon}",
                        "offers": {
                          "@type": "Offer",
                          "priceCurrency": "KES",
                          "url": "${siteurl}",
                          "price": "${service.price}"
                        }
                      }`}
                  </script>

                  {/* <div className="prodTitleRating">
                <div className="prodTitleRatingContainer">
                  <span>4.5</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="15"
                    viewBox="0 0 51 48"
                  >
                    <path
                      fill=" #FFFFFF"
                      stroke="none"
                      d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                    ></path>
                  </svg>
                </div>
                <span className="ratingDisplay">260 ratings & 120 reviews</span>
              </div> */}
                </div>
                <div className="OtcPriceBox_Container">
                  <div className="OtcPriceBox__atc-box___30PES">
                    {/* <div className="SocialCue__social-cue-box___2fIau">
                      <span className="SocialCue__views-icon___1G33-">
                        <img src={SocialCue} alt="social-cue" />
                      </span>
                      {product.prescribed && !isPrescriptionPicked && (
                        <Alert key="warning" variant="warning">
                          Prescription required
                        </Alert>
                      )}
                    </div> */}
                    <div className="OtcPriceBox__price-box___p13HY">
                      <div className="PriceBoxPlanOption__option-container___3BpVv">
                        <div className="PriceBoxPlanOption__flex___3c7VS PriceBoxPlanOption__align-center___6zWOL PriceBoxPlanOption__margin-bottom-16___3STIk">
                          <div className="PriceBoxPlanOption__margin-right-12___3C4UW PriceBoxPlanOption__padding-top-9___1_vWO"></div>
                          <div>
                          {!!service.discount ? (
                              <span className="PriceBoxPlanOption__offer-price___3v9x8 PriceBoxPlanOption__offer-price-cp___2QPU_">
                                <span>Price:</span>
                                <span
                                  className="PriceBoxPlanOption__margin-right-4___2aqFt PriceBoxPlanOption__stike___pDQVN"
                                  style={{ color: 'gray' }}
                                >
                                  {' '}
                                  Ksh {service.price}{' '}
                                </span>
                                Ksh
                                {Math.round(
                                  service.price *
                                    ((100 - service.discount) / 100),
                                )}
                                <span className="PriceBoxPlanOption__discount___iN_jm">
                                  {' '}
                                  {service.discount}% Off
                                </span>
                              </span>
                            ) : (
                              <span className="PriceBoxPlanOption__offer-price___3v9x8 PriceBoxPlanOption__offer-price-cp___2QPU_">
                                {service.showPrice ? (
                                  <span>
                                    Price: Ksh{service.price}
                                  </span>
                                ) : (
                                  <span>Price on Request</span>
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <img style={{ width: '50px' }} src={prescriptionUrl} />
                      <p style={{ color: 'red', textAlign: 'center' }}>
                        {error}
                      </p>
                      {isLoading && <div>Loading...</div>}
                      
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            onChange={e => handleChangePrescription(e)}
                          />

                          <UploadBtn
                            style={{
                              border: '2px solid red',
                              padding: '0.5rem',
                            }}
                          >
                            <MdAddCircleOutline
                              style={{ marginRight: '10px', width: '100%' }}
                            />
                            Upload Request
                          </UploadBtn>
                        </label>
                     

                      {(isPrescriptionPicked) && (
                        <>
                        <AddCartBtn onClick={handleReset}>Reset</AddCartBtn>
                        <AddCartBtn onClick={() => addToCart() && handleShow()}>
                          Add To Cart
                        </AddCartBtn>
                        </>
                      )} */}

                      <a
                        href={`https://wa.me/+254792640973?text=Hello Afyabook, regarding ${service.name.toLowerCase()}.&source=servicepage`}
                        target="_blank"
                      >
                        <AddCartBtn
                          css={`
                            //padding: 1rem 1rem;
                            background: #4cbb17;
                          `}
                        >
                          <BsWhatsapp style={{ display: 'inline' }} /> Chat on
                          WhatsApp
                        </AddCartBtn>
                      </a>
                      <AddCartBtn onClick={() => navigate(`/doctors/`)}>
                        Consult a Doctor!
                      </AddCartBtn>
                      <AddCartBtn
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href)
                          alert('Link Copied to Clipboard!')
                        }}
                      >
                        <BsShareFill
                          style={{ display: 'inline', marginRight: '5px' }}
                        />
                        Share Link
                      </AddCartBtn>
                    </div>
                  </div>
                </div>
                {success && (
                  <>
                    <StyledModal show={show} animation={true} on={handleShow}>
                      <ContentWrapper>
                        <IconCont>
                          <CloseIcon onClick={handleClose}></CloseIcon>
                        </IconCont>
                        <ModalTxt>
                          Kindly Login to Upload your Lab/Diagnostic Request
                        </ModalTxt>
                        <ModalDesc>
                          click The link below to Login <br />
                          <Link to="/Login">here</Link>
                        </ModalDesc>
                      </ContentWrapper>
                    </StyledModal>
                  </>
                )}
                <Tabs pageContext={pageContext} />
              </div>
            </div>
          </HeaderContainer>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: '10px',
          }}
        >
          <Link to="/userprofile">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1690319466/Record_and_monitor_your_measurements_here_1_mgbql5.jpg"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
          <Link to="/upload-prescription">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1690319480/upload_prescription_1_ad5qex.jpg"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
        </div>
        
        <div>
          <div>
            {/* <h3 style={{ marginLeft: '10px' }}>Offers</h3> */}
            <ProductSlideWrapper>
              <Slider {...settings}>
                {services.map(service => {
                  return (
                    service.category.includes('offers') && (
                      <Med services={service} />
                    )
                  )
                })}
              </Slider>
            </ProductSlideWrapper>
          </div>
        </div>
      </div>
      <BotNav />
      <Footer />
    </div>
  )
}
